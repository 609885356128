import React, { useState, useEffect, useContext } from 'react';
import GrantAnswersCard from './GrantAnswersCard';
import Cookies from 'js-cookie';
import OpenAI from 'openai';
import Skeleton from 'react-loading-skeleton';
import { encodingForModel } from 'js-tiktoken';
import Airtable from 'airtable';
import {
  isNumbered,
  numberingCompare,
  revise,
  isAnswer,
  hasSampleAnswer,
  setContents,
  setShowNotif,
  removeQueryParameters,
} from './helpers.js';
import Notification from './Notification';
import {
  PencilIcon,
  EyeIcon,
  DocumentTextIcon,
  InformationCircleIcon,
  SparklesIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';
import { InfinitySpin } from 'react-loader-spinner';
import { useUser } from '@clerk/clerk-react';
import { AppContext } from './AppContext.js';

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;

const base = new Airtable({ apiKey: apiKey }).base(baseId);

function hasLessThanNWords(text, wordLimit) {
  if (!text) {
    return false;
  }
  // Split the text by spaces to count the words
  const words = text.trim().split(/\s+/);

  // Check if the number of words is less than the specified limit
  return words.length < wordLimit;
}

export default function GrantAnswersForm({
  loading,
  grant,
  grants,
  company,
  questions,
  setQuestions,
  answers,
  setAnswers,
  admin,
}) {
  // Notification states
  const [autodraftNotif, setAutodraftNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  const { isSignedIn, isLoaded, user } = useUser();
  const { clerkUser, setClerkUser } = useContext(AppContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredAnswers, setFilteredAnswers] = useState([]);
  const [pastAnswersContext, setPastAnswersContext] = useState([]);
  const [questionsToDisplay, setQuestionsToDisplay] = useState([null]);
  const [grantAutodraftLoading, setGrantAutodraftLoading] = useState(false);
  const [sampleAnswerShow, setSampleAnswerShow] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [showPastAnswers, setShowPastAnswers] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [prompt1, setPrompt1] = useState(
    `You will be given information about a company and then be asked to answer a question regarding the company. Answer the question from the perspective of the company. Do not make up answer for questions you do not have enough context for. If a question needs more context, write "NEED MORE CONTEXT" and write what we know so far\r\n`
  );
  const [prompt2, setPrompt2] = useState(
    `Name: ${company.fields.Name}. \r\n Employees: ${company.fields.Employees}. \r\n Country: ${company.fields.Country}. \r\n Province: ${company.fields.ProvinceOrState}. \r\n Industry: ${company.fields.Industry}. \r\n Founded: ${company.fields.Founded}. Profitable: ${company.fields['Profitable?']}\r\n`
  );
  const [prompt3, setPrompt3] = useState(
    `This is the description of the company named ${company.fields.Name}. \r\n Description: ${company.fields.Description} \r\n`
  );
  const [prompt4, setPrompt4] = useState(
    `The following is additional information about the company.\r\n ${company.fields.LongDescription}`
  );
  const [useEditedPrompt, setUseEditedPrompt] = useState(false);

  // State to manage the expanded content for each question
  const [expandedQuestions, setExpandedQuestions] = useState({});

  // State to manage editing and feedback
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [feedbackText, setFeedbackText] = useState('');
  const [currentAnswers, setCurrentAnswers] = useState({});

  // Initialization of states
  useEffect(() => {
    // Exit useEffect if states aren't ready
    if (!isLoaded || !user || !isSignedIn) return;

    // Set clerkUser and userRecord states
    setClerkUser(user);
  }, [isLoaded, user, isSignedIn]);

  useEffect(() => {
    let questionAndAnswers = [];

    const answersByCompany = answers
      .filter((answer) => answer.fields.CompanyID === company.id)
      .filter((answer) => hasLessThanNWords(answer.fields.AnswerText, 50));

    answersByCompany.forEach((answer) => {
      // Find the corresponding question based on QuestionID
      const matchingQuestion = questions.find(
        (question) => question.fields.QuestionID === answer.fields.QuestionID
      );

      if (matchingQuestion) {
        questionAndAnswers.push({
          question: matchingQuestion.fields.QuestionText,
          answer: answer.fields.AnswerText,
        });
      }
    });

    setPastAnswersContext(questionAndAnswers);
  }, [answers, company, questions]);

  useEffect(() => {
    // Filter the past answers based on the search query
    const results = pastAnswersContext.filter((item) =>
      item?.question?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredAnswers(results);
  }, [searchQuery, pastAnswersContext]);

  const handleInputChange = (index, field, value) => {
    const newData = [...pastAnswersContext];
    newData[index][field] = value;
    setPastAnswersContext(newData);
  };

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_APIKEY,
    dangerouslyAllowBrowser: true,
  });

  const getQuestionsByGrant = (grantIdentifier) => {
    if (loading) return [];
    return questions.filter((question) => {
      if (!question?.fields?.grantIdentifier) return false;
      else
        return question?.fields?.grantIdentifier
          ?.split(',')
          .includes(grantIdentifier);
    });
  };

  function currencyFormat(num) {
    return num
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const getAnswer = (answersArray, companyId, questionId, grantIdentifier) => {
    let possibleAnswers = answersArray.filter(
      (ans) =>
        ans?.fields?.AnswerText &&
        ans.fields?.CompanyID === companyId &&
        ans.fields.QuestionID === questionId &&
        ans.fields.grantIdentifier === grantIdentifier
    );
    if (possibleAnswers.length === 0) return null;
    else if (possibleAnswers.length === 1) {
      return possibleAnswers[0];
    } else {
      console.log(
        'There are multiple possible answers for question ' +
        questionId +
        ' from Company ' +
        companyId +
        '.'
      );
      console.log(possibleAnswers);
      if (
        !possibleAnswers[0]?.fields?.AnswerText ||
        possibleAnswers[0].fields.AnswerText === ''
      )
        return null;
      return possibleAnswers[0];
    }
  };

  // Requires that each question in questionsArray has an 'answer' property which contains the answer object.
  // If no answer, then the answer property is null.
  const getQuestionsAnswersSorted = (questionsArray) => {
    return questionsArray.sort((a, b) => {
      if (a.fields.Link && b.fields.Link);
      else if (a.fields.Link) return -1;
      else if (b.fields.Link) return 1;

      // Questions that have answers that need revision are at the top.
      if (revise(a.answer) && revise(b.answer));
      else if (revise(a.answer)) return -1;
      else if (revise(b.answer)) return 1;

      // Answers to Questions that are not numbered should always appear at the bottom
      if (!isNumbered(a?.fields?.QuestionText) && isAnswer(a.answer)) return 1;
      else if (!isNumbered(b?.fields?.QuestionText) && isAnswer(b.answer))
        return -1;

      // Numbered Questions appear after un-numbered questions
      if (
        isNumbered(a?.fields?.QuestionText) &&
        isNumbered(b?.fields?.QuestionText)
      )
        return numberingCompare(a.fields.QuestionText, b.fields.QuestionText);
      else if (isNumbered(a?.fields?.QuestionText)) return 1;
      else if (isNumbered(b?.fields?.QuestionText)) return -1;

      // Answered Questions appear after un-answered questions
      if (!isAnswer(a.answer) && !isAnswer(b.answer)) return 0;
      else if (!isAnswer(a.answer)) return -1;
      else if (!isAnswer(b.answer)) return 1;

      return 0;
    });
  };

  const getQuestionsToDisplay = (answersArray, grantIdentifier, companyId) => {
    return getQuestionsAnswersSorted(
      getQuestionsByGrant(grantIdentifier).map((question) => {
        let newQuestion = question;
        newQuestion['answer'] = getAnswer(
          answersArray,
          companyId,
          question.fields.QuestionID,
          grantIdentifier
        );
        return newQuestion;
      })
    );
  };

  // Requires that the companyRecord represents a valid Company with the following fields being not undefined:
  // Name, Employees, Country, ProvinceOrState, Industry, Founded, Profitable?, Description, LongDescription
  const getInitialAutodraftMessages = (companyRecord) => {
    let prompts = [
      `You will be given information about a company and then be asked to answer a question regarding the company. Answer the question from the perspective of the company.\r\n`,

      `Name: ${companyRecord.fields.Name}. \r\n Employees: ${companyRecord.fields.Employees}. \r\n Country: ${companyRecord.fields.Country}. \r\n Province: ${companyRecord.fields.ProvinceOrState}. \r\n Industry: ${companyRecord.fields.Industry}. \r\n Founded: ${companyRecord.fields.Founded}. Profitable: ${companyRecord.fields['Profitable?']}\r\n`,

      `This is the description of the company named ${companyRecord.fields.Name}. \r\n Description: ${companyRecord.fields.Description} \r\n`,

      `The following is additional information about the company.\r\n ${companyRecord.fields.LongDescription}`,
    ];
    if (useEditedPrompt) {
      prompts = [prompt1, prompt2, prompt3, prompt4];
    }
    return [
      {
        role: 'system',
        content: prompts[0],
      },
      {
        role: 'user',
        content: prompts[1],
      },
      {
        role: 'user',
        content: prompts[2],
      },
      {
        role: 'user',
        content: prompts[3],
      },
    ];
  };

  const submitAnswer = (
    companyId,
    questionId,
    grantIdentifier,
    answer,
    newAnswerText
  ) => {
    const answersTableId = 'tblaUCzGrjHIiKgQ3';

    if (isAnswer(answer) || (answer?.fields && answer.id)) {
      // Send PATCH request to edit answer
      fetch(
        'https://api.airtable.com/v0/' +
        baseId +
        '/' +
        answersTableId +
        '/' +
        answer.id,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
          },

          body: JSON.stringify({
            fields: {
              AnswerText: newAnswerText,
            },
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw Error(JSON.stringify(response));
          }

          return response.json();
        })
        .then((data) => {
          return new Promise((resolve, reject) => {
            JSON.stringify(data).toLowerCase().includes('error')
              ? reject(data)
              : resolve(data);
          });
        })
        .then(() => {
          base('answers')
            .select({ view: 'Grid view' })
            .all()
            .then((records) => {
              setAnswers(records);
            });
        })
        .catch((error) => {
          console.error('Error in editing answer:', error);
        });
    } else {
      // Send POST request to create answer
      fetch(
        'https://api.airtable.com/v0/' + baseId + '/' + answersTableId,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fields: {
              AnswerText: newAnswerText,
              CompanyID: companyId,
              grantIdentifier: grantIdentifier,
              QuestionID: questionId,
              AnswerID: `${Date.now()}`,
            },
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw Error(JSON.stringify(response));
          }

          return response.json();
        })
        .then((data) => {
          return new Promise((resolve, reject) => {
            JSON.stringify(data).toLowerCase().includes('error')
              ? reject(data)
              : resolve(data);
          });
        })
        .then(() => {
          base('answers')
            .select({ view: 'Grid view' })
            .all()
            .then((records) => {
              setAnswers(records);
            });
        })
        .catch((error) => {
          console.error('Error in submitting Answer:', error);
        });
    }
  };

  const autodraftAnswer = async (companyRecord, question) => {
    if (!question) {
      console.error('Invalid question');
      return;
    }

    const questionText = question.fields.QuestionText;

    const maxInputTokens = 16385 - 1000; // Change to match the model, -1000 to account for inaccuracies in tiktoken
    const model = 'gpt-4';

    const questionMessages =
      pastAnswersContext?.length > 0
        ? [
          {
            role: 'user',
            content: `The following is a series of questions of the company and the company's answers:\r\n`,
          },
          ...pastAnswersContext.map((q) => ({
            role: 'user',
            content: `Question: ${q.question}. \r\n Answer: ${q.answer}`,
          })),
        ]
        : [];

    const initialMessages = getInitialAutodraftMessages(companyRecord);

    let questionToAskMessage = {
      role: 'user',
      content: `Based on what you know about ${companyRecord.fields.Name}, answer this question: ${questionText}.`,
    };

    if (hasSampleAnswer(question)) {
      questionToAskMessage.content += `\n Please format your answer according to this sample answer: ${question.fields.SampleAnswer}`;
    }

    let messages = [
      ...initialMessages,
      ...questionMessages,
      questionToAskMessage,
    ];

    // Remove enough context to not exceed maxInputTokens
    try {
      const encoder = encodingForModel(model);
      let tokenCount = encoder.encode(
        messages.map((message) => message.content).join('\n')
      ).length;
      let numberOfQuestionsRemoved = 0;
      if (tokenCount > maxInputTokens) {
        while (tokenCount > maxInputTokens) {
          if (questionMessages.length === 0) {
            throw new Error(
              `Number of tokens required ${tokenCount} is larger than maximum input tokens ${maxInputTokens}`
            );
          }

          questionMessages.splice(0, 1);
          ++numberOfQuestionsRemoved;

          messages = [
            ...initialMessages,
            ...questionMessages,
            questionToAskMessage,
          ];
          tokenCount = encoder.encode(
            messages.map((message) => message.content).join('\n')
          ).length;
        }
      }

      console.log('context messages', questionMessages);
      console.log('we removed', numberOfQuestionsRemoved, 'questions');
    } catch (e) {
      console.error('Error:', e);
    }

    try {
      const chatCompletion = await openai.chat.completions.create({
        model: model,
        messages: messages,
      });

      return chatCompletion;
    } catch (e) {
      console.error(e);
      return 'N/A';
    }
  };

  // Added missing getAutodraftQuestionsText function
  const getAutodraftQuestionsText = (companyRecord, questions) => {
    return Promise.all(
      questions.map((q) => {
        if (isAnswer(q?.answer)) {
          const qText = `<strong>${q.fields.QuestionText}</strong>\nAnswer: ${q.answer.fields?.AnswerText}`;
          return qText;
        } else {
          const answer = q?.answer;

          return new Promise((resolve, reject) => {
            autodraftAnswer(companyRecord, q)
              .then((data) => {
                const qAnswer = data.choices[0].message.content;

                // Save the generated answer
                submitAnswer(
                  company.id,
                  q.fields.QuestionID,
                  `${grant?.fields?.Name}-${removeQueryParameters(
                    grant?.fields?.GrantUrl
                  )}`,
                  answer,
                  qAnswer
                );

                resolve(
                  `<strong>${q.fields.QuestionText}</strong>\nAnswer: <p style="color: red; display: inline-flex;">[ AutoDrafted ]</p> ${qAnswer}`
                );
              })
              .catch((error) => {
                console.error(
                  `Error in autodrafting answer for question ${q.fields.QuestionText}, ${q.fields.QuestionID}`,
                  error
                );
                reject(error);
              });
          });
        }
      })
    );
  };

  // Handles the onClick event for the Grant Autodraft button.
  const handleAutodraftGrant = async (
    grantRecord,
    companyRecord,
    questions
  ) => {
    try {
      // Add your Zapier webhook URL or other logic
      const webhookURL = `https://hooks.zapier.com/hooks/catch/16093819/${process.env.REACT_APP_ZAPIER_AUTODRAFT}/`;

      setGrantAutodraftLoading(true);

      const questionsText = (
        await getAutodraftQuestionsText(companyRecord, questions)
      ).join('\n\n');

      setContents(
        {
          type: 'success',
          heading: 'Autodraft Successful',
          message:
            'Document should be ready in Google Drive. Check Slack for the link',
        },
        setAutodraftNotif
      );
      setShowNotif(true, setAutodraftNotif);

      fetch(webhookURL, {
        method: 'POST',
        body: JSON.stringify({
          grant: grantRecord.fields.Name,
          company: companyRecord.fields.Name,
          questions: questionsText,
          amount: currencyFormat(grantRecord.fields.Amount),
          clientEmail: clerkUser.primaryEmailAddress.emailAddress,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(JSON.stringify(response));
          }

          return response.json();
        })
        .then((data) => console.log('data', data))
        .catch((e) => console.error('Error in sending autodraft request: ', e))
        .finally(() => setGrantAutodraftLoading(false));
    } catch (err) {
      setContents(
        {
          type: 'error',
          heading: 'Autodraft Unsuccessful',
          message: `${err.message}`,
        },
        setAutodraftNotif
      );
      setShowNotif(true, setAutodraftNotif);
    }
  };

  function updateCompany(fieldName, companyId, newValue) {
    fetch(
      'https://api.airtable.com/v0/' +
      baseId +
      '/' +
      companiesTableId +
      '/' +
      companyId,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: {
            [fieldName]: newValue,
          },
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error('Error:', error));
  }

  // useEffect to compute questionsToDisplay state
  useEffect(() => {
    if (!questions || questions.length === 0) return;
    if (!answers || answers.length === 0) return;
    if (!grant?.id) return;
    if (!company?.id) return;

    setQuestionsToDisplay(
      getQuestionsToDisplay(
        answers,
        `${grant?.fields?.Name}-${removeQueryParameters(grant?.fields?.GrantUrl)}`,
        company.id
      )
    );
  }, [questions, answers, grant, company]);

  // Function to toggle the edit form for a question
  const toggleEditForm = (questionID) => {
    setEditingQuestionId((prevId) => (prevId === questionID ? null : questionID));
  };

  // Function to handle answer changes
  const handleAnswerChange = (questionID, newText) => {
    setCurrentAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionID]: newText,
    }));
  };

  // Function to handle feedback changes
  const handleFeedbackChange = (newText) => {
    setFeedbackText(newText);
  };

  // Function to save the updated answer
  const handleSaveAnswer = (questionID) => {
    const question = questionsToDisplay.find(
      (q) => q.fields.QuestionID === questionID
    );
    const answer = question['answer'];
    const newAnswerText = currentAnswers[questionID];

    // Call submitAnswer with appropriate parameters
    submitAnswer(
      company.id,
      question.fields.QuestionID,
      `${grant.fields.Name}-${removeQueryParameters(grant.fields.GrantUrl)}`,
      answer,
      newAnswerText
    );

    // After saving, close the edit form
    setEditingQuestionId(null);
  };

  // Function to handle autodraft
  const handleAutodraft = async (questionID) => {
    const question = questionsToDisplay.find(
      (q) => q.fields.QuestionID === questionID
    );
    const answer = question['answer'];

    try {
      const data = await autodraftAnswer(company, question);
      const generatedAnswer = data.choices[0].message.content;

      // Update the answer in state
      handleAnswerChange(questionID, generatedAnswer);

      // Save the answer
      submitAnswer(
        company.id,
        question.fields.QuestionID,
        `${grant.fields.Name}-${removeQueryParameters(grant.fields.GrantUrl)}`,
        answer,
        generatedAnswer
      );
    } catch (error) {
      console.error('Error generating answer:', error);
    }
  };

  return loading ? (
    <div className="py-4 items-center">
      {[1, 2, 3].map((item) => (
        <div
          key={item}
          className="mt-3 flex items-center justify-center w-full max-w-screen"
        >
          <div className="mt-3 my-1 items-left justify-left border-t border-gray-100">
            <Skeleton height={50} width={500} />
          </div>
        </div>
      ))}
    </div>
  ) : questionsToDisplay.length === 0 ? (
    <div className="flex justify-center items-center text-center mt-3">
      <div className="relative max-w-2xl justify-center items-center w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        <CheckIcon
          className="mx-auto h-12 w-12 text-gray-400 hover:text-green-400"
          aria-hidden
        />
        <span className="mt-2 block text-md text-gray-900">
          We don't have any questions for you at the moment
        </span>
        <span className="mt-2 block text-sm text-gray-900">
          Currently working on getting your team{' '}
          {grant?.fields?.Name ? grant.fields.Name : 'this funding opportunity'}
        </span>
      </div>
    </div>
  ) : (
    <div className="bg-white min-h-screen">
      {/* Header Section */}
      <div className="mt-2 mb-2 px-4 text-center">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-2xl font-semibold text-gray-900">
            Let's get you {grant.fields.Name} for ${currencyFormat(grant.fields.Amount)}/yr
          </h1>

          {admin && (
            <div className="mt-6 flex flex-wrap justify-center gap-4">
              {/* Admin Action Buttons */}
              <button
                onClick={async () =>
                  await handleAutodraftGrant(grant, company, questionsToDisplay)
                }
                className="flex items-center text-gray-600 hover:text-blue-600 focus:outline-none"
                disabled={grantAutodraftLoading}
              >
                <SparklesIcon className="h-6 w-6" />
                <span className="ml-2 text-sm font-medium">
                  {grantAutodraftLoading ? 'Loading...' : 'Autodraft Grant'}
                </span>
              </button>
              <button
                onClick={() => setShowPrompt((prev) => !prev)}
                className="flex items-center text-gray-600 hover:text-blue-600 focus:outline-none"
              >
                {showPrompt ? (
                  <CheckIcon className="h-6 w-6" />
                ) : (
                  <EyeIcon className="h-6 w-6" />
                )}
                <span className="ml-2 text-sm font-medium">Prompt</span>
              </button>
              <button
                onClick={() => setShowPastAnswers((prev) => !prev)}
                className="flex items-center text-gray-600 hover:text-blue-600 focus:outline-none"
              >
                <DocumentTextIcon className="h-6 w-6" />
                <span className="ml-2 text-sm font-medium">Context</span>
              </button>
              <button
                onClick={() => setShowInfo((prev) => !prev)}
                className="flex items-center text-gray-600 hover:text-blue-600 focus:outline-none"
              >
                <InformationCircleIcon className="h-6 w-6" />
                <span className="ml-2 text-sm font-medium">Company Info</span>
              </button>
              <button
                onClick={() => setSampleAnswerShow((prev) => !prev)}
                className="flex items-center text-gray-600 hover:text-blue-600 focus:outline-none"
              >
                <DocumentTextIcon className="h-6 w-6" />
                <span className="ml-2 text-sm font-medium">
                  {sampleAnswerShow ? 'Hide Samples' : 'Show Samples'}
                </span>
              </button>
              <button
                onClick={() => window.open('/edit-grant-questions', '_blank')}
                className="flex items-center text-gray-600 hover:text-blue-600 focus:outline-none"
              >
                <PencilIcon className="h-6 w-6" />
                <span className="ml-2 text-sm font-medium">Edit Grant</span>
              </button>
            </div>
          )}

          {/* {admin && (
            <p className="mt-4 text-sm text-gray-500">
              Currently using{' '}
              <span className="font-medium text-gray-700">
                {useEditedPrompt ? 'edited prompt' : 'default prompt'}
              </span>
            </p>
          )} */}
        </div>
      </div>

      {/* Company Info Section */}
      {admin && showInfo && (
        <div className="text-center mx-auto">
          <h3 className="font-bold">Company Info</h3>
          <div className="my-3">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                let newValue = e.target[`longDescription${company?.id}`].value;
                updateCompany('LongDescription', company?.id, newValue);
              }}
            >
              <div className="sm:grid sm:grid-cols-1 sm:gap-3">
                <textarea
                  rows="12"
                  defaultValue={company?.fields?.LongDescription}
                  id={`longDescription${company?.id}`}
                  className="w-full p-2 rounded-xl border border-gray-300 text-black"
                />
                <button
                  type="submit"
                  className="mt-2 p-2 w-full sm:w-auto text-sm font-bold text-white bg-gray-900 rounded-md hover:bg-gray-800 focus:outline-none"
                >
                  Update Company Scraped Info
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Past Answers Section */}
      {admin && showPastAnswers && (
        <div className="space-y-4">
          <h2 className="font-bold text-xl text-center">Past Answers</h2>
          <hr />
          <div className="flex justify-center">
            <input
              type="text"
              placeholder="Search questions..."
              className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full max-w-md"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          {/* Filtered Past Answers */}
          <div className="space-y-4">
            {filteredAnswers.map((item, index) => (
              <div key={index} className="flex flex-col space-y-2 px-4 md:px-20">
                <label className="text-sm font-medium text-gray-700">
                  {item.question}
                </label>
                <textarea
                  className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={item.answer}
                  onChange={(e) => handleInputChange(index, 'answer', e.target.value)}
                  rows="3"
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Autodraft Prompt Section */}
      {admin && showPrompt && (
        <div className="text-center mx-auto my-16">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setUseEditedPrompt((prev) => !prev);
            }}
          >
            <h2 className="text-xl font-bold mb-4">
              Autodraft Prompt (Runs Once for All Questions)
            </h2>
            <hr className="mb-4" />
            <div className="space-y-4">
              {/* System Instructions */}
              <div>
                <label className="block text-left font-medium mb-1">
                  System Instructions
                </label>
                <textarea
                  rows="4"
                  value={prompt1}
                  onChange={(e) => setPrompt1(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              {/* Company Details */}
              <div>
                <label className="block text-left font-medium mb-1">
                  Company Details
                </label>
                <textarea
                  rows="4"
                  value={prompt2}
                  onChange={(e) => setPrompt2(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              {/* Company Description */}
              <div>
                <label className="block text-left font-medium mb-1">
                  Company Description
                </label>
                <textarea
                  rows="4"
                  value={prompt3}
                  onChange={(e) => setPrompt3(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              {/* Company Long Description */}
              <div>
                <label className="block text-left font-medium mb-1">
                  Company Long Description (scraped info)
                </label>
                <textarea
                  rows="4"
                  value={prompt4}
                  onChange={(e) => setPrompt4(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              {/* Submit Button */}
              <button
                type="submit"
                className="mt-4 px-4 py-2 font-bold text-white bg-gray-900 rounded-md hover:bg-gray-800 focus:outline-none"
              >
                {useEditedPrompt ? 'Use Default Prompt' : 'Use Edited Prompt'}
              </button>
              <p className="mt-2">
                Currently using {useEditedPrompt ? 'edited prompt' : 'default prompt'}
              </p>
            </div>
          </form>
        </div>
      )}

      {/* TODO - Sample Answers Section - Add your sample answers display logic here */}
      {/* {sampleAnswerShow && (
        <div className="mt-6 max-w-3xl mx-auto bg-white rounded-xl shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Sample Answers</h2>
          <p className="text-gray-500">Sample answers will be displayed here.</p>
        </div>
      )} */}

      {/* Questions Feed */}
      <div className="mt-3 mx-auto max-w-3xl">
        <ul role="list" className="space-y-4">
          {questionsToDisplay.map((question) => {
            if (!Boolean(question)) return null;

            const answerRecord = question['answer'];
            const questionID = question.fields.QuestionID;
            const isEditing = editingQuestionId === questionID;
            const questionText = question.fields.QuestionText || '';

            // Get the current answer and feedback from state or default
            const currentAnswer =
              isEditing && currentAnswers[questionID] !== undefined
                ? currentAnswers[questionID]
                : answerRecord?.fields?.AnswerText || '';
            const currentFeedback = feedbackText || '';

            return (
              <li key={questionID} className="bg-white rounded-xl shadow-sm">
                {/* Question and Answer Card */}
                <div className="p-6">
                  {/* Question Text */}
                  <div
                    onClick={() => toggleEditForm(questionID)}
                    className="cursor-pointer"
                  >
                    <p className="text-lg font-semibold text-gray-900">
                      {questionText}
                    </p>
                  </div>

                  {/* Answer Text */}
                  {!isEditing && (
                    <p className="text-gray-800 mt-2">
                      {currentAnswer || 'No answer provided yet.'}
                    </p>
                  )}

                  {/* Edit Form */}
                  {isEditing && (
                    <div className="mt-4">
                      <textarea
                        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={currentAnswer}
                        onChange={(e) => {
                          handleAnswerChange(questionID, e.target.value);
                        }}
                        rows="4"
                        placeholder="Write your answer here..."
                      />
                      {/* Feedback Input */}
                      {/* <textarea
                        className="w-full mt-3 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={currentFeedback}
                        onChange={(e) => {
                          handleFeedbackChange(e.target.value);
                        }}
                        rows="2"
                        placeholder="Add feedback: How can they improve their answer?"
                      /> */}
                      <div className="flex justify-end space-x-2 mt-4">
                        {/* <button
                          onClick={() => handleAutodraft(questionID)}
                          className="rounded-md bg-white px-2.5 py-1.5 text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                        >
                          Autodraft
                        </button> */}
                        <button
                          onClick={() => {
                            handleSaveAnswer(questionID);
                          }}
                          className="rounded-md bg-white px-2.5 py-1.5 text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                        >
                          Resubmit
                        </button>
                        <button
                          onClick={() => toggleEditForm(questionID)}
                          className="rounded-md bg-white px-2.5 py-1.5 text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      </div>

      {/* OLD BUT USEFUL - ALL LOGIC HERE - Grant Answers Card */}
      <h1 className="my-8 text-2xl font-semibold text-gray-900 text-center">
        ADMIN ONLY
      </h1>
      <div className="mt-3 mx-4 items-center justify-center w-full max-w-screen">
        <div className="items-center justify-center">
          <ul role="list" className="divide-y divide-gray-200">
            {questionsToDisplay.map((question) => {
              if (!Boolean(question)) return;

              const answer = question['answer'];

              return (
                <div
                  className="max-w-screen w-full"
                  key={question.fields.QuestionID}
                >
                  <GrantAnswersCard
                    grant={grant}
                    answer={answer}
                    setAnswers={setAnswers}
                    company={company}
                    question={question}
                    // companyQuestions={questionsByCompany}
                    admin={admin}
                    sampleAnswerShow={sampleAnswerShow}
                    pastAnswersContext={pastAnswersContext}
                  />
                </div>
              );
            })}
          </ul>
        </div>
      </div>

      {/* Notification Component */}
      <Notification
        showNotif={autodraftNotif.showNotif}
        setShowNotif={(val) => setAutodraftNotif(val, setAutodraftNotif)}
        contents={autodraftNotif.contents}
      />
    </div>
  );
}
