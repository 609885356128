import React, { useState, useRef, useContext } from 'react';
import { getQuestionsToDisplay, handleAutodraftGrant } from "./Autodraft.js";
import { currencyFormat, setContents, setShowNotif, checkEmail, removeQueryParameters } from "./helpers";
import Notification from "./Notification.js";
import { AppContext } from './AppContext.js';

const SendAutoDraftModal = ({
    isOpen,
    closeModal,
    defaultEmail,
    company,
    userEmail,
    upgrade,
    currencyFormat,
    answers,
    questions,
    isGrant,
    setShowReferralNotification
}) => {
    const emailInputRef = useRef(null);
    const { pastAnswersContext } = useContext(AppContext);
    if (!isOpen) return null;

    const handleSubmit = async () => {
        const email = emailInputRef.current.value;
        console.log(email)
        try {
            // Close Modal
            closeModal()

            // Set Notification
            // SEE EDIT STUFF NOTIFICATION

            // Send POST request to Zapier
            if (isGrant) {
                fetch('https://hooks.zapier.com/hooks/catch/13965335/2ota6y3/', {
                    method: "POST",
                    body: JSON.stringify({
                        CompanyName: company?.fields?.Name,
                        ClientEmail: email,
                        RequesterEmail: userEmail,
                        PartnerEmail: '',
                        Link: `${window.location.origin}/grants-form/${company.id}/${upgrade.recordId}`,
                        UpgradeName: upgrade?.fields?.Name,
                        Amount: currencyFormat(upgrade?.fields?.AnnualMargin),
                        ClientName: company?.fields?.Name,
                        Date: new Date().toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }),
                        UpgradeType: "Grant",
                        Deadline: upgrade?.fields?.Deadline,
                        Password: "",
                        SlackNotification: true,
                    })
                })

                console.log("This is where we're sending the autodraft!", email)

                const processedQuestions = await getQuestionsToDisplay(answers, `${upgrade?.fields?.Name}-${removeQueryParameters(upgrade?.fields?.GrantUrl)}`, company?.id, questions);

                console.log("upgrade", upgrade)
                console.log("company", company)
                console.log("processedQuestions", processedQuestions)
                console.log("email", email)
                console.log("pastAnswersContext", pastAnswersContext)

                if (processedQuestions) {
                    // AUTODRAFT TEMP OFF
                    handleAutodraftGrant(upgrade, company, processedQuestions, email, pastAnswersContext);

                }

            }

            setContents(
				{
					type: "success",
					heading: "Sent!",
					message: `Draft was sent to this email. Check your inbox :)`,
				},
				setShowReferralNotification
			);
			setShowNotif(true, setShowReferralNotification);

            var response = {}
            response.status = 200

            if (response.status === 200) {
                console.log("Email sent successfully to Zapier");
            } else {
                console.error("Error sending email to Zapier");
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
                <h2 className="text-2xl font-bold mb-4">Send Your Auto Draft To</h2>
                <label className="block mb-2">Email:</label>
                <input
                    type="email"
                    defaultValue={defaultEmail}
                    ref={emailInputRef}
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                />

                <div className="flex justify-between">
                    <button
                        onClick={closeModal}
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                    >
                        Close
                    </button>

                    <button
                        onClick={handleSubmit}
                        className="bg-green-500 text-white px-4 py-2 rounded"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SendAutoDraftModal;
