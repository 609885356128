import { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import Cookies from 'js-cookie';
import Fuse from 'fuse.js';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';
import Progress from './Progress';
import { setShowNotif, setContents, currencyFormat } from './helpers.js';
import {
  ArrowUpCircleIcon,
  ArrowTopRightOnSquareIcon,
  ExclamationTriangleIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  MagnifyingGlassCircleIcon,
  LockClosedIcon,
} from '@heroicons/react/20/solid';
import Automation from './Automation.js';
import useBase from './useBase.js';
import {
  Bars3Icon,
  PhoneIcon,
  Cog6ToothIcon,
  HomeIcon,
  XMarkIcon,
  BoltIcon,
  LightBulbIcon,
  CurrencyDollarIcon,
  BanknotesIcon,
  ClipboardDocumentListIcon,
  BuildingOffice2Icon,
  GiftIcon,
  TrophyIcon,
  IdentificationIcon,
  ArrowTrendingUpIcon,
  FolderIcon,
  GlobeAmericasIcon,
} from '@heroicons/react/24/outline';
import { useNavigate, useLocation } from 'react-router-dom';
import Airtable from 'airtable';
import Company from './Company';
import Onboarding from './Onboarding';
import Financing from './Financing';
import Notification from './Notification';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import {
  checkValidDomainName,
  currencyToNumber,
  checkEmail,
  getWebURL,
  classNames,
  extractDomain,
} from './helpers.js';
import { countryCodes, regionCodes } from './helperData';
import EmailModal from './EmailModal';
import SidePanel from './SidePanel';
import Summary from './Summary';
import UpgradeList from './UpgradeList';
import JITModal from './JITModal';
import IndustriesMappingModal from './IndustriesMappingModal.js';
import NotificationButtons from './NotificationButtons';
import AutomationContext from './AutomationContext';
import { UserButton, UserProfile, useUser } from '@clerk/clerk-react';
import Perks from './Perks.js';
import AllGrants from './AllGrants.jsx';
import PlansPage from './PlansPage.jsx';
import MyNetwork from './MyNetwork.js';
import { AppContext } from './AppContext.js';
import './App.css';

LogRocket.init('upgraded/upgraded-app');
setupLogRocketReact(LogRocket);

const navigationNew = [
  { name: 'Dashboard', href: '#', icon: HomeIcon, current: false, pro: false },
  {
    name: 'Company Info',
    href: '#company-info',
    icon: IdentificationIcon,
    current: false,
    pro: false,
  },
  {
    name: 'Vendor Savings',
    href: '#vendor-savings',
    icon: LightBulbIcon,
    current: false,
    pro: true,
  },
  {
    name: 'Grants',
    href: '#grants',
    icon: CurrencyDollarIcon,
    current: false,
    pro: true,
  },
  {
    name: 'Growth Upgrades',
    href: '#top-upgrades',
    icon: ArrowTrendingUpIcon,
    current: false,
    pro: false,
  },
  {
    name: 'Automations',
    href: '#automations',
    icon: BoltIcon,
    current: false,
    pro: true,
  },
  {
    name: 'Financing',
    href: '#financing',
    icon: BanknotesIcon,
    current: false,
    pro: false,
  },
  {
    name: 'My Reports',
    href: '#myreports',
    icon: FolderIcon,
    current: false,
    pro: true,
  },
  {
    name: 'Onboarding',
    href: '#onboarding',
    icon: ClipboardDocumentListIcon,
    current: false,
    pro: false,
  },
];

const userNavigation = [
  {
    name: 'All Company Info',
    href: `/company-info/${getQueryVariable('website')}`,
  },
];

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;
const usersTableId = process.env.REACT_APP_AIR_USERS;
const base = new Airtable({ apiKey: apiKey }).base(baseId);
// const admin = Cookies.get("domain") === "all" && getQueryVariable(process.env.REACT_APP_QUERY_SECRET_1) === "true";
const clayApiKey = process.env.REACT_APP_CLAY_API_KEY;

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] == variable) {
      // LogRocket.identify("", {
      // 	name: "James Morrison",
      // 	email: "jamesmorrison@" + pair[1],
      // 	subscriptionType: "pro",
      // });
      return pair[1];
    }
  }
  return false;
}

function capitalize(str) {
  if (!str) return str; // This checks for null, undefined, and empty string
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// main application
export default function App() {
  const {
    company,
    setCompany,
    clerkUser,
    setClerkUser,
    userRecord,
    setUserRecord,
    open,
    setOpen,
    domainStatus,
    setDomainStatus,
    loading,
    setLoading,
    grants,
    setGrants,
    grantLoading,
    setGrantLoading,
    JITWorking,
    setJITWorking,
    unknownDomain,
    setUnknownDomain,
    matchedDiscounts,
    setMatchedDiscounts,
    totalTechSavings,
    setTotalTechSavings,
    eligibleGrants,
    setEligibleGrants,
    grantsSum,
    setGrantsSum,
    questions,
    answers,
  } = useContext(AppContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [submitEnrichNotif, setSubmitEnrichNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  // global automation states used for endless fries
  const [automationPeople, setAutomationPeople] = useState({});

  // old auth section
  // const [signedUp, setSignedUp] = useState(false);
  // const [userEmail, setUserEmail] = useState("");
  // const [restricted, setRestricted] = useState(false);
  // const [emailCloseable, setEmailCloseable] = useState(false);

  // to create reactive states using cookies
  // deprecated bcuz new auth
  // useEffect(() => {
  // 	if (Cookies.get("signed_up")) {
  // 		setSignedUp(Cookies.get("signed_up"));
  // 	}
  // 	if (Cookies.get("email")) {
  // 		setUserEmail(Cookies.get("email"));
  // 	}
  // 	if (Cookies.get("domain")) {
  // 		setUserDomain(Cookies.get("domain"));
  // 	}
  // }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .grsf-global .grsf-widget {
          margin-right: 80px !important;
          margin-bottom: 15px !important;
      }
    `;
    document.head.appendChild(style);

    return () => document.head.removeChild(style);
  }, []);

  // New Auth -- For Redirecting from "/" to "/?website=companyDomain" after they sign-in or sign-up
  const { isSignedIn, isLoaded, user } = useUser();
  const [users, setUsers] = useState();
  const { loading: usersLoading, error: usersError } = useBase(
    'users',
    setUsers
  );
  const [paid, setPaid] = useState(true);
  const [userDomain, setUserDomain] = useState('');
  const [admin, setAdmin] = useState(false);
  const [restricted, setRestricted] = useState(false);
  const [userReports, setUserReports] = useState([]);
  const [purchasedReports, setPurchasedReports] = useState([]);

  const [submitFormNotif, setSubmitFormNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  const [submitGrantNotif, setSubmitGrantNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  const [errorGrantNotif, setErrorGrantNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  const [techPipeline, setTechPipeline] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  // this state represents the availability of the company in airtable
  const [domainAdded, setDomainAdded] = useState(false);

  // alternative way to solve grants filter problem
  const [flaggedGrants, setFlaggedGrants] = useState([]);

  // State to contain all the grants the current company is interested in (stored on companies table)
  const [interestedInGrants, setInterestedInGrants] = useState([]);

  // State to track whether JIT Modal is showing or not
  const [showJITModal, setShowJITModal] = useState(false);

  // State to track the request email for JIT
  const [requestEmail, setRequestEmail] = useState('');

  // ensure only run submitenrich once so we don't get duplicates in airtable
  const hasRunOnce = useRef(false);

  // to prevent code from the useeffect from running twice
  let trackNum = 0;

  // timeend
  const [timeEnd, setTimeEnd] = useState(false);

  // state tells us if we need to conduct airtable polling
  // const [airtablePoll, setAirtablePoll] = useState(false);

  // this state is used to keep track of number of companies from airtable
  const [recordCount, setRecordCount] = useState(0);

  // State to contain the discounts fetched from airtable.
  const [discounts, setDiscounts] = useState([]);
  const [showIndustryModal, setShowIndustryModal] = useState(false);
  const [industries, setIndustries] = useState({});
  const [industryRecords, setIndustryRecords] = useState({});
  const [domainFromEmail, setdomainFromEmail] = useState('getupgraded.ca');
  const [getEmail, setGetEmail] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [offset, setOffset] = useState();
  const [showAmount, setShowAmount] = useState(0);
  const [grantWriters, setGrantWriters] = useState([]);
  const [upgrades, setUpgrades] = useState([]);

  /*
  -----------------------
       Notifications
  -----------------------
  */

  // Savings Estimate Notification
  const [reqSavingsNotif, setReqSavingsNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  // Company Form Submit Notification
  const [companyFormNotif, setCompanyFormNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  // Referral Notification
  const [emailModalNotif, setEmailModalNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const pushEmailtoAirtableLeads = async (email) => {
    base('leads').create(
      [
        {
          fields: {
            Email: email,
          },
        },
      ],

      function (err, records) {
        if (err) {
          console.error(err);
          return;
        }

        records.forEach(function (record) {
          console.log(record.getId());
        });
      }
    );
  };

  const handleSubmit = (domain) => {
    // Handle email
    if (domain?.includes('@')) {
      //If an email is detected, first add that to the leads table

      pushEmailtoAirtableLeads(domain);

      domain = domain?.split('@')[1]; // then split the email from the domain
    }

    // Handle website.
    if (
      domain?.startsWith('https://www.') ||
      domain?.startsWith('http://www.') ||
      domain?.startsWith('www.') ||
      domain?.startsWith('http://') ||
      domain?.startsWith('https://')
    ) {
      domain = domain.split('://')[1];

      if (domain?.includes('www.')) {
        domain = domain.split('www.')[1];
      }

      if (domain?.includes('/')) {
        domain = domain.split('/')[0];
      }
    }

    // Checking if the input submitted is a url
    const url = getWebURL(domain);
    if (url !== false) {
      domain = url;
    }

    // Creating the contents object for the notification
    const output = checkValidDomainName(domain);
    let contents = { type: '', heading: '', message: '' };
    if (output.result === 'Error') {
      contents.type = 'error';
      contents.heading = `Error in Fetching ${domain}❗`;
      contents.message = output.message;
    }
    // if the domain is valid (i.e. output.result==="Success")
    if (output.result === 'Success') {
      // If the company exists in airtable
      if (domainStatus === 'known') {
        const presentCompany = companies.filter(
          (company) => company.fields.Domain === domain
        )[0];
        window.location.href = `/?website=${domain}&company=${presentCompany.fields.Name}`;

        // if the company doesn't exist in airtable
      } else if (domainStatus === 'unknown') {
        // If the domain entered HAS been previously entered during this session
        // if (Cookies.get("enteredDomains")?.split(",").includes(domain)) {
        contents.type = 'success';
        contents.heading = 'Your Report is Being Upgraded';
        contents.message =
          "We're currently creating your report. It'll be emailed to you as soon as it's ready!";
        // } else {
        // -->  If the domain entered HAS NOT been entered previously in this session

        // Ensure enteredDomains is always an array
        const enteredDomains = Cookies.get('enteredDomains')
          ? Cookies.get('enteredDomains').split(',')
          : [];

        // Add the new domain to the array
        enteredDomains.push(domain);

        // Set the cookie with the updated array, converting it back to a comma-separated string
        Cookies.set('enteredDomains', enteredDomains.join(','), { expires: 1 });

        // Begin JIT report running for new domain
        window.location.href = `/?website=${domain}`;
        // }
      }
    }

    // If there is a notification to show
    if (contents.type != '') {
      setContents(contents, setSubmitEnrichNotif);
      setShowNotif(true, setSubmitEnrichNotif);
    }
  };

  function submitEmailInvite(email, comment, reportLink) {
    comment = comment + reportLink || 'Hey there lets see how this works!';

    var request = email + ' was just referred for savings ';

    setShowNotif(true, setEmailModalNotif);
    const content = {
      type: 'success',
      heading: `You just referred ${email}!`,
      message:
        "You'll be sent the greater of $500 or 15% of the first 12 months of revenue",
    };
    setContents(content, setEmailModalNotif);

    // https://hooks.zapier.com/hooks/catch/13965335/3ztn14f/
    fetch(`https://hooks.zapier.com/hooks/catch/13965335/3ztn14f/`, {
      method: 'POST',
      body: JSON.stringify({ request, email, comment }),
    })
      .then((response) => response.json())
      .then((data) => { })
      .catch((error) => console.error(error));
  }

  function submitEnrich(
    domain,
    companyId,
    userId,
    updatedCompanyReports,
    updatedUserReports
  ) {
    // const output = checkValidDomainName(domain);

    // let contents = { type: "", heading: "", message: "" };
    // if (output.result === "Error") {
    // 	contents.type = "error";
    // 	contents.heading = `Error in Fetching ${domain}❗`;
    // } else if (output.result === "Success") {
    // 	contents.type = "success";
    // 	contents.heading = `Success`;
    // }
    // contents.message = output.message;

    const website = domain || getQueryVariable('website');
    var request = `Savings estimate just ran! User: ${user.primaryEmailAddress.emailAddress}`;

    // JIT wait time currently 30 secs
    // if (output.result === "Success") {
    setShowJITModal(true);

    if (companyId) {
      fetch(
        'https://api.airtable.com/v0/' +
        baseId +
        '/' +
        companiesTableId +
        '/' +
        companyId,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
          },

          body: JSON.stringify({
            fields: {
              Reports: updatedCompanyReports,
            },
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log('patch company table');
        })
        .catch((error) => console.error('Error:', error));
    }

    if (userId) {
      fetch(
        'https://api.airtable.com/v0/' +
        baseId +
        '/' +
        usersTableId +
        '/' +
        userId,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fields: {
              Reports: updatedUserReports,
            },
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log('patch user table');
        })
        .catch((error) => console.error('Error:', error));
    }

    // console.log("admin ??", admin);
    // let reportingEmails = "true";
    // if (admin) reportingEmails = "false";

    // Creating a new empty record for JIT companies.
    // This record contains the Domain of the Company, as well as some default values for different fields.
    fetch('https://api.airtable.com/v0/' + baseId + '/' + companiesTableId, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fields: {
          Name: capitalize(getQueryVariable('website').split('.')[0]),
          Domain: getQueryVariable('website'),
          Industry: 'Technology',
          Technologies: 'N/A',
          RequestEmail: user.primaryEmailAddress.emailAddress,
          ProvinceOrState: 'ON',
          Address: 'Toronto, Ontario',
          Employees: 10,
          Country: 'CA',
          Founded: 2000,
          AnnualRevenue: 1000000,
          AvgDealSize: 10000,
          ConversionRate: 2,
          Debt: 100000,
          AnnualTraffic: 10000,
          OperationalCosts: 500000,
          ListSize: 10000,
          InterestedIn:
            'CanExport SME,DS4Y,Student Work Placement Program,MITACS Business Strategy Internship,Canada Job Grant (CJG),Canada Small Business Financing Program (CSBFP)',
          Activity: JSON.stringify({
            Grants: [],
            'Tech Discounts': [],
            Automations: {
              excludePeople: [],
              personMap: {},
            },
            LatestActivity: 'n/a',
          }),
          'subscribed?': 'false',
          blurMe: 'true',
          reportingEmails: 'true',
          sendFrequency: 7,
          // bccEmails: 'will@getupgraded.ca',
          fromName: 'Will Richman',
          People1Name: 'there',
          lastSent: '04/25/2024',
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log("Created 'Empty' Record in database", data))
      .catch((error) => console.error('Error:', error));

    setTimeout(() => setFormSubmitted(true), 1000);

    setTimeout(() => {
      setJITWorking(false);
    }, 30000);

    fetch(
      `https://hooks.zapier.com/hooks/catch/13965335/${process.env.REACT_APP_ZAPIER_SAVINGS}/`,
      {
        method: 'POST',
        body: JSON.stringify({ request, website }),
      }
    );

    try {
      fetch(
        `https://mycorsproxy-tuto-35940f676099.herokuapp.com/https://api.clay.com/v3/sources/webhook/${process.env.REACT_APP_CLAY_ENRICH}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${clayApiKey}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
              'Origin, X-Requested-With, Content-Type, Accept',
          },
          body: JSON.stringify({
            email: 'williamrichman@gmail.com',
            domain: website,
          }),
        }
      )
        .then((response) => {
          console.log('api call successful');
        })
        .catch((err) => {
          console.log('this was the problem', err);
        });
      console.log(`❗Finished fetching for ${website} ❗`);
    } catch (error) {
      console.error('Error in Fetching JIT: ', error);
    }
    // }

    // if (!unknownDomain && contents.type !== "success") {
    // 	setContents(contents, setReqSavingsNotif);
    // 	setShowNotif(true, setReqSavingsNotif);
    // }
  }

  // for scrolling to correct section after load
  useEffect(() => {
    if (!grantLoading) {
      const hash = window.location.hash;
      if (hash) {
        setTimeout(() => {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 500);
      }
    }
  }, [grantLoading]);

  // CELLO
  // useEffect(() => {
  //   const SECRET = 'pnmh0wKBTV3Tg6ejAgbYcDyO+s/f5aQ6OohOKJ8W2phtwr0NbnqQXdC19TZh83zdugzeReqV59q1Z0ea8WbB0pMqGyNeY8vTI6/79zZono6PrgDm9LObMzCtolUCLlzEJlE8jAIosADPPFGOJlg1JdHZDQyO3V//nUfnKCmIvBv2ZvKGVoMWgmzOPJuFJ9ShRvxoFghJoMV+v1/Ty6Y57zFQX6i937McBaI/cbJI+AumlBb3IWxAYIC9nztrEEOYFfVjhK44uUEwRpMUq4ZhdCUgTtHW3i31fO8c2sjD/atxsyQ/pYdt7Pp7Y8J5Cn1UcWwNAWsSqefXZI13Gjq9JidkB1xPwIQzQ7q6AWCfDd1d7DZeiE5nJ6fauLLT8q8E437o1bK3Sty2GJO+U1rU2QefAI+d9N5PqmJNuTtAhGrFDE1gG3rzNx5FjVqtOKLhg6Nnl9I9H7gTXU2F4nt2+CPyHMAN4n16TxLuSv7zoIu2EEey3N4ZvxtRXLbQZjMayI8BEB9/xJxM3OIxxP5zOlc2D2uA0x7sfgxGwLCGIjWglsldqrVqSM/VpFivoDrlRtdJ5kQaT/rEH6wRCtqZg0YyBOlO98pFaUswnVOYJz+amPvcvfFd6MVQHqoytSDw25PtG8uzo2J02IgvF6qk5myY0zMsnoFE8+sFYog4WQ4='; // Replace with your actual product secret
  //   const tokenPayload = {
  //     productId: 'stage-app.getupgraded.ca', // Replace with your actual product ID
  //     productUserId: user.id, // Replace with a method to fetch or default user ID
  //   };

  //   const token = sign(tokenPayload, SECRET, {
  //     algorithm: 'HS512',
  //   });

  //   console.log("token", token);
  //   console.log("user.id",user.id);
  //   // Possibly store token in state or send to backend/API
  // }, []);

  // Fetch industries from airtable
  useEffect(() => {
    base('industries')
      .select({ view: 'Grid view' })
      .all()
      .then((data) => {
        let industriesObj = {};
        data.map(
          (rec) =>
          (industriesObj[rec.fields.Industry] =
            rec.fields.SubIndustries.split(',').map((item) =>
              item.trim().toLowerCase()
            ))
        );

        setIndustryRecords(data);
        setIndustries(industriesObj);
      })
      .catch((err) => console.log(err));
  }, []);

  // Fetch grants from airtable
  const { loading: grantsLoading, error: grantsError } = useBase(
    'deduped_grants',
    setGrants,
    grants,
  );
  if (grantsError) console.error('Error in fetching grants', grantsError);

  // get all grantwriters
  useEffect(() => {
    base('grant_writers_profiles')
      .select({ view: 'Grid view' })
      .all()
      .then((data) => {
        setGrantWriters(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [JITModalSubmit, setJITModalSubmit] = useState(false);

  // initialization of states
  useEffect(() => {
    // exit useEffect if states aren't ready
    if (!isLoaded || !user || !isSignedIn || !users) return;

    // set clerkUser and userRecord states
    setClerkUser(user);

    const curUser = users.filter((curUser) =>
      user.emailAddresses.some(
        (email) => email.emailAddress === curUser.fields.Email
      )
    )[0];

    if (!curUser) return;

    setUserRecord(curUser);

    const userDomain = curUser?.fields?.Domain;
    setUserDomain(userDomain);

    const reports = curUser?.fields?.Reports ? curUser.fields.Reports.split(',') : []
    setUserReports(reports);

    const activatedReports = curUser?.fields?.PurchasedReports
      ? curUser.fields.PurchasedReports.split(',')
      : []
    setPurchasedReports(activatedReports);

    const isAdmin =
      curUser?.fields?.Domain ===
      process.env.REACT_APP_COOL_EMAIL;
    setAdmin(isAdmin);

    LogRocket.identify(user.id, {
      name: user.fullName,
      email: curUser?.fields?.Email,
    });

    if (getQueryVariable('website') === false) {
      window.location.href = `/?website=${userDomain}`;
    }

    const noPermissions =
      !activatedReports.includes(getQueryVariable('website')) &&
      userDomain !== getQueryVariable('website');

    setPaid(curUser?.fields?.Paid);

    if (noPermissions) setPaid('unpaid');

    base('companies')
      .select({
        view: 'Grid view',
        filterByFormula: `REGEX_MATCH({Domain}, '^${getQueryVariable(
          'website'
        )}$')`,
      })
      .all()
      .then((records) => {
        const curCompany = records[0];

        setCompany(curCompany);

        // //                                                                                             // //
        // Resetting which domains are saved in our cookies based on whether they are now in airtable or not //
        // //
        // console.log("loaded company", records);                                                                           // //
        // let JITDomains = Cookies.get("enteredDomains")?.split(",");
        // let newDomains = records.map((record) => record?.fields?.Domain);
        // let userEnteredDomains = [];

        // if (JITDomains) {
        // 	for (const domain of JITDomains) {
        // 		if (!newDomains.includes(domain)) {
        // 			userEnteredDomains.push(domain);
        // 		}
        // 	}
        // }

        // Cookies.set("enteredDomains", userEnteredDomains.length === 0 ? "" : userEnteredDomains, { expires: 1 });
        // // //                                                                                             // //
        // // Resetting which domains are saved in our cookies based on whether they are now in airtable or not //
        // // //                                                                                             // //

        // let possibleCompanies = records.filter((company) => company.fields.Domain == getQueryVariable("website"));
        // let curCompany = undefined;

        // if (possibleCompanies.length > 1) {
        // 	// merging records
        // 	let mergedRecord = possibleCompanies.slice(1).reduce((accumulator, current) => {
        // 		let temp = { id: accumulator.id, fields: accumulator.fields };

        // 		for (let field of Object.keys(accumulator.fields)) {
        // 			if (accumulator.fields[field] === undefined || accumulator.fields[field] === "") {
        // 				temp.fields[field] = current.fields[field];
        // 			}
        // 		}

        // 		for (let field of Object.keys(current.fields)) {
        // 			if (!(field in temp.fields)) {
        // 				temp.fields[field] = current.fields[field];
        // 			}
        // 		}

        // 		return temp;
        // 	}, possibleCompanies[0]);

        // 	// deleting duplicate companies in airtable
        // 	for (let companyRecord of possibleCompanies.slice(1)) {
        // 		const recordId = companyRecord.id;

        // 		fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + recordId, {
        // 			method: "DELETE",
        // 			headers: {
        // 				Authorization: `Bearer ${apiKey}`,
        // 				"Content-Type": "application/json",
        // 			},
        // 		})
        // 			.then((response) => response.json())
        // 			.then((confirmation) => {
        // 				console.log("Duplicate Company Deleted Confirmation", confirmation);
        // 			})
        // 			.catch((e) => console.error("Error deleting duplicate company", e));
        // 	}

        // 	// Updating the merged record in the airtable
        // 	fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + mergedRecord.id, {
        // 		method: "PATCH",
        // 		headers: {
        // 			Authorization: `Bearer ${apiKey}`,
        // 			"Content-Type": "application/json",
        // 		},

        // 		body: JSON.stringify({
        // 			fields: mergedRecord.fields,
        // 		}),
        // 	})
        // 		.then((response) => response.json())
        // 		.then((data) => {
        // 			console.log("Updated merged record to airtable", data);
        // 		})
        // 		.catch((error) => console.error("Error:", error));

        // 	setCompanies([...records.filter((record) => record.fields.Domain !== getQueryVariable("website")), mergedRecord]);
        // 	curCompany = mergedRecord;
        // } else {
        // 	setCompanies(records);
        // 	curCompany = records.filter((cmpy) => cmpy.fields.Domain === getQueryVariable("website"))[0];
        // }

        // this will run if company was not found in airtable
        // we now know that the domain is not from airtable, so we setUnknownDomain to true
        // trying to enrich the page with new company
        if (curCompany === undefined) {
          // arguments for submit enrich
          const domain = getQueryVariable('website');

          if (!domain) return;

          let userCompany;

          (async () => {
            const records = await base('companies')
              .select({
                view: 'Grid view',
                filterByFormula: `REGEX_MATCH({Domain}, '^${userDomain}$')`,
              })
              .all();
            userCompany = records[0];

            const curCompanyReports = userCompany?.fields?.Reports || '';

            const updatedCompanyReports =
              curCompanyReports.length > 0
                ? `${curCompanyReports},${domain}`
                : domain;

            const curUserReports = curUser?.fields?.Reports || '';

            const updatedUserReports =
              curUserReports.length > 0
                ? `${curUserReports},${domain}`
                : domain;

            const userCompanyId = userCompany?.id;

            const userId = curUser?.id;

            setUnknownDomain(true);

            // Check if the function has run before
            if (!hasRunOnce.current) {
              // creates airtable record
              submitEnrich(
                domain,
                userCompanyId,
                userId,
                updatedCompanyReports,
                updatedUserReports
              );
              hasRunOnce.current = true; // Mark the function as run
            }

          })();
        } else {
          if (!getQueryVariable('company')) {
            window.location.href = `/?website=${curCompany.fields.Domain}&company=${curCompany.fields.Name}`;
          }

          base('discounts copy')
            .select({ view: 'Grid view' })
            .all()
            .then((discountRecords) => {
              let newDiscounts = findDiscounts(
                curCompany?.fields?.Technologies,
                discountRecords,
                false
              );

              setDiscounts(discountRecords);
              // setTotalTechSavings(newDiscounts.reduce((partialSum, a) => partialSum + a?.fields?.AnnualMargin, 0));
              setMatchedDiscounts(newDiscounts ? newDiscounts : []);

              /* ------- 

                Code for filtering technologies

                ------- */

              // let technologies = curCompany?.fields?.Technologies?.split(",")
              //   .map((item) => item.trim())
              //   .filter((technology) =>
              //     discountRecords
              //       .map((discount) => discount?.fields?.Title?.trim().toLowerCase())
              //       .some((title) => title.includes(technology.toLowerCase().trim()))
              //   );

              // let newCurCompany = {
              //   ...curCompany,
              //   fields: {
              //     ...curCompany.fields,
              //     Technologies: technologies.join(", "),
              //   },
              // };
              // setCompany(newCurCompany);
              // setCompanies([...records.filter(item => item.id !== newCurCompany.id), newCurCompany])

              /* ------- 

                Code for filtering technologies

              ------- */

              setCompany(curCompany);
              setLoading(false);
            })
            .catch((err) => console.log('bad call', err));
        }

        // const newRecordCount = records.length;
        // console.log('current number of companies', newRecordCount);
        // setRecordCount(newRecordCount);
        // setBaseChangeCount(prevCount => prevCount + 1);
      });
  }, [isLoaded, user, domainAdded, isSignedIn, users, unknownDomain]);

  useEffect(() => {
    // if (unknownDomain && !JITWorking && !domainAdded && requestEmail !== "" && location.pathname !== '/report-pending')

    // After the 30 seconds, if the JIT domain hasn't been added to airtable, then we send the user to the report-pending page.
    // - There is a 1200 ms timeout on navigating which allows for the progress bar to finish reaching 100% before the redirect.

    if (JITWorking || !JITModalSubmit) return;

    const timeoutId = setTimeout(() => {
      navigate('/report-pending');
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [JITWorking, JITModalSubmit]);

  // useEffect(() => {
  //   // if unknown domain is true, then set isLoading to true.
  //   if (!unknownDomain) {
  //    setTimeout(() => {
  //      setLoading(false);
  //    }, 6000);
  //   }
  // }, [unknownDomain])

  useEffect(() => {
    if (formSubmitted) {
      // Perform actions after form submission
      base('companies')
        .select({ view: 'Grid view' })
        .all()
        .then((records) => {
          setCompanies(records);
          let company = records.filter(
            (company) => company.fields.Domain == getQueryVariable('website')
          )[0];
          setCompany(company);
          console.log('✅company is reloaded!');
        });

      // Reset the formSubmitted state variable
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  // SECTION DEDICATED TO JIT
  // -------------------------------->

  // trying another way to retrieve newest company from airtable
  // checking for modifications in companies section of database
  useEffect(() => {
    // will only run if company is not in airtable yet
    if (unknownDomain && requestEmail) {
      const fetchData = async () => {
        try {
          // from airtable we grab the companies record
          // then we find the number of rows for companies

          const records = await base('companies')
            .select({
              view: 'Grid view',
              filterByFormula: `REGEX_MATCH({Domain}, '^${getQueryVariable(
                'website'
              )}$')`,
            })
            .all();

          console.log('POLLING');

          const JITCompany = records[0];

          if (
            JITCompany?.fields?.RequestEmail &&
            JITCompany?.fields?.LinkedInUrl
          ) {
            setDomainAdded(true);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();

      setInterval(fetchData, 1000); // Poll every 1 second

      // return () => clearInterval(interval);
    }
  }, [unknownDomain, requestEmail]);

  // will run if the unknown company has been added to airtable
  // we will retrieve the company info and change to url to include both the website and company query params
  useEffect(() => {
    if (domainAdded && unknownDomain && JITModalSubmit) {
      const runAsync = async () => {
        try {
          const records = await base('companies')
            .select({
              view: 'Grid view',
              filterByFormula: `REGEX_MATCH({Domain}, '^${getQueryVariable(
                'website'
              )}$')`,
            })
            .all();

          const company = records[0];

          if (company?.fields?.Name) {
            window.location.href = `/?website=${company.fields.Domain}&company=${company.fields.Name}`;
          }
        } catch { }
      };

      runAsync();
    }
  }, [domainAdded, unknownDomain, JITModalSubmit]);

  // useEffect(() => {
  // 	// if (unknownDomain && !JITWorking && !domainAdded && requestEmail !== "" && location.pathname !== '/report-pending')

  // 	// After the 30 seconds, if the JIT domain hasn't been added to airtable, then we send the user to the report-pending page.
  // 	// - There is a 1200 ms timeout on navigating which allows for the progress bar to finish reaching 100% before the redirect.

  // 	if (JITWorking || domainAdded) return;

  // 	const timeoutId = setTimeout(() => {
  // 		navigate("/report-pending");
  // 	}, 4000);

  // 	return () => clearTimeout(timeoutId);

  // }, [JITWorking, domainAdded]);

  // Fetch upgrades from airtable
  useEffect(() => {
    let ignore = false;

    (async () => {
      if (ignore) return;
      base('upgrades')
        .select({ view: 'Grid view', Favourite: true })
        .all()
        .then((upgrades) => {
          setUpgrades(upgrades);
        });
    })();

    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    if (!company || grants.length === 0) return;

    if (!unknownDomain) {
      setEligibleGrants(
        find_grants(
          company.fields.Country,
          company.fields.ProvinceOrState,
          company.fields.Address,
          company.fields.Founded,
          company.fields.Industry,
          company.fields.Employees,
          company.fields['Profitable?']
        ).filter(
          (grant) =>
            !flaggedGrants
              .map((grant) => grant.fields.Name)
              .includes(grant.fields.Name)
        )
      );

      //MARKER
      setGrantsSum(
        find_grants(
          company.fields.Country,
          company.fields.ProvinceOrState,
          company.fields.Address,
          company.fields.Founded,
          company.fields.Industry,
          company.fields.Employees,
          company.fields['Profitable?'],
          'sum'
        )
      );
    }

    setGrantLoading(false);
  }, [grants, company, flaggedGrants]);

  // this is for getting excluded grants from airtable
  useEffect(() => {
    if (!company) return;

    if (!unknownDomain && company?.fields?.Domain != '') {
      const excluded = company?.fields?.ExcludeGrants
        ? company.fields.ExcludeGrants.split(',')
        : [];
      setFlaggedGrants(
        find_grants(
          company.fields.Country,
          company.fields.ProvinceOrState,
          company.fields.Address,
          company.fields.Founded,
          company.fields.Industry,
          company.fields.Employees,
          company.fields['Profitable?']
        ).filter((grant) => excluded.includes(grant.fields.Name))
      );
    }
  }, [grants, company]);

  // This useEffect is for fetching the InterestedIn grants from the companies table
  useEffect(() => {
    if (
      unknownDomain ||
      !company?.fields?.Domain ||
      !company?.fields ||
      !grants?.length
    )
      return;

    const interestedInNamesList = company?.fields?.InterestedIn
      ? company?.fields?.InterestedIn.split(',').map((item) => item.trim())
      : [];

    setInterestedInGrants(
      find_grants(
        company.fields.Country,
        company.fields.ProvinceOrState,
        company.fields.Address,
        company.fields.Founded,
        company.fields.Industry,
        company.fields.Employees,
        company.fields['Profitable?']
      ).filter((grant) => interestedInNamesList.includes(grant.fields.Name))
    );
  }, [grants, company]);

  const addGrants = () => {
    for (const upgrade of interestedInGrants) {
      fetch('https://hooks.zapier.com/hooks/catch/16093847/3vv9abn/', {
        method: 'POST',
        body: JSON.stringify({
          CompanyName: company?.fields?.Name,
          ClientEmail: '',
          PartnerEmail: '',
          UpgradeName: upgrade?.fields?.Name,
          Amount: currencyFormat(upgrade?.fields?.AnnualMargin),
          ClientName: '',
          Date: new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
          UpgradeType: 'Grant',
        }),
      });
    }
  };

  // adjust settings for navigation
  useEffect(() => {
    if (loading) {
      return;
    }

    let navLinks = document.querySelectorAll('nav a');
    let logo = document.getElementById('logo');
    let pfp = document.getElementById('profile-pic');

    logo?.addEventListener('click', () =>
      window.scrollTo({ top: 0, behavior: 'smooth' })
    );
    pfp?.addEventListener('click', (e) => {
      e.preventDefault();
      setOpen((prev) => !prev);
    });

    const handleNavClick = (e) => {
      e.preventDefault();

      const href = e.currentTarget.getAttribute('href');
      if (href === '#') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }

      const target = document.querySelector(href);
      if (target) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              observer.disconnect();
              window.history.pushState(null, null, href); // Update the URL hash
              // console.log('Scroll animation complete'); // Callback function
            }
          },
          { threshold: 1.0 } // Fully in view
        );

        observer.observe(target);
        target.scrollIntoView({ behavior: 'smooth' });
      }
    };

    navLinks.forEach((link) => {
      link.addEventListener('click', handleNavClick);
    });
  }, [loading]);

  function findDiscounts(techStack, allDiscounts, returnSum = false) {
    if (!techStack) return null;

    let technologies = techStack?.split(',').map((item) => item.trim());
    let newDiscounts = [];

    // fuzzy match implementation
    for (let i = 0; i < technologies.length; i++) {
      const options = {
        shouldSort: true,
        threshold: 0.1, // Adjust this threshold to control the fuzziness of the matching
        keys: ['name'], // Specify the object property to search for job titles
      };

      const fuse = new Fuse(
        allDiscounts.map((discount) => ({
          discount,
          name: discount?.fields?.Title?.trim().toLowerCase(),
        })),
        options
      );

      const results = fuse.search(technologies[i].toLowerCase().trim());

      const bestDiscounts = results.map((result) => ({
        value: result.item.discount,
        score: result.score,
      }));
      // const bestDiscounts = results.map((result) => ({
      //   value: result.item.discount,
      //   score: result.score,
      // }));

      // console.log('best discounts', bestDiscounts);

      bestDiscounts.map((discount) => {
        const upgrade = {
          id: discount.value.id,
          score: discount.score,
          fields: {
            invoiceNumber: '00012',
            href: discount?.value?.fields?.Url,
            AnnualMargin: currencyToNumber(discount?.value?.fields?.MaxSavings),
            Description: discount?.value?.fields?.Description,
            Tactic: 'Automate Accounts Receivable',
            Function: 'Technology',
            client: 'Upgrade #00012',
            category: 'Finance',
            psuccess: 0.85,
            TTV: '< 30',
            client_time_required: '0.5',
            icon: ArrowUpCircleIcon,
            DiscountId: discount?.value?.fields?.DiscountId,
            Name: discount?.value?.fields?.Title,
            AssociatedTechnology: technologies[i],
            Eligible_Region: '',
            Eligible_Industry: '',
            Eligible_Employees: '',
            Eligible_Revenue: '',
            Eligible_Founded: '',
            Description_2: discount?.value?.fields?.Value,
            Eligible_Activities: '',
            Eligible_Applicants: '',
            Funding_Amount: '',
            Source: discount?.value?.fields?.Source,
            Details: discount?.value?.fields?.Details,
            Technology_Upgrade: true,
            InstructionsEmail: discount?.value?.fields?.InstructionsEmail,
            NewOrExisting: discounts?.value?.fields?.New_or_Existing,
          },
        };

        if (
          typeof upgrade?.fields?.AnnualMargin === 'number' &&
          !isNaN(upgrade?.fields?.AnnualMargin) &&
          typeof upgrade?.fields?.Name === 'string'
        ) {
          newDiscounts.push(upgrade);
        }
      });
    }

    newDiscounts = newDiscounts.sort(
      (a, b) => b?.fields?.AnnualMargin - a?.fields?.AnnualMargin
    );

    // delete duplicates
    if (newDiscounts.length !== 0) {
      let temp = newDiscounts.filter(
        (discount, index, self) =>
          index === self.findIndex((t) => t.id === discount.id)
      );
      newDiscounts = temp;
    }

    return returnSum
      ? newDiscounts.reduce(
        (partialSum, a) => partialSum + a?.fields?.AnnualMargin,
        0
      )
      : newDiscounts;
  }

  function find_grants(
    country,
    province,
    locality,
    founded,
    industry,
    employees,
    profitable,
    typeOfMoney = 'both'
  ) {
    // https://developers.google.com/sheets/api/samples/reading

    // Defaults set in find_grants function :
    // var country = country || "CA";
    // var province = province || "";
    // var founded = founded || "2017";
    // var industry = industry || "Technology";
    // var locality = locality || "Toronto, Ontario";
    // var employees = employees || "15";
    // var profitable = profitable || "Yes";
    // var typeOfMoney = typeOfMoney || "both";

    var country = country || '';
    var province = province || '';
    var founded = founded || '';
    var industry = industry || '';
    var locality = locality || '';
    var employees = employees || '';
    var profitable = profitable || '';
    var typeOfMoney = typeOfMoney || '';

    var data = grants;
    var sheetLength = grants?.length;
    // var parametersArray = [industry, founded, locality, country, employees];
    var sum = 0;
    var new_array = [];

    const countryNames = Object.values(countryCodes); // [canada, united states, ...]
    const countryOfCompany = country
      .trim()
      .toUpperCase()
      .split(',')
      .map((code) => countryCodes[code]); // turns "CA,BR" => [canada, brazil]
    const cityState = locality
      .trim()
      .toLowerCase()
      .split(',')
      .map((item) => item.trim()); // ide"toronto, ontario" to ["toronto", "ontario"]

    let provincesOfCompany = province
      .split(',')
      .map((item) => item.trim())
      .map((code) => {
        for (const countryCode of Object.keys(regionCodes)) {
          if (Object.keys(regionCodes[countryCode]).includes(code)) {
            return regionCodes[countryCode][code];
          }
        }
        return false;
      })
      .filter(Boolean)
      .map((item) => item.trim().toLowerCase());

    // changing abbreviation to full name
    if (
      cityState[1]?.length === 2 && // this means the state / province is a 2 digit code
      (Object.keys(regionCodes['CA']).includes(cityState[1]) ||
        Object.keys(regionCodes['US']).includes(cityState[1])) // this means the 2 digit code is contained in our data
    ) {
      const canadaProvince = regionCodes['CA'][cityState[1].toUpperCase()];
      const usState = regionCodes['US'][cityState[1].toUpperCase()];

      if (canadaProvince) {
        cityState[1] = canadaProvince;
      } else if (usState) {
        cityState[1] = usState;
      }
    }

    var industry = industry?.trim()?.toLowerCase().split(',').map(element => element.trim());

    for (var i = 1; i < sheetLength; i++) {
      // TO DO: Process both company industry and grant industry as an array
      // console.log("this is grant industry", data[i].fields.Eligible_Industry.trim().toLowerCase().split(','))
      // console.log('Industry', industry)
      var grantIndustry =
        data[i]?.fields?.Eligible_Industry?.toLowerCase().split(',').map(element => element.trim()) ||
        '';

      function checkIndustry(specificIndustry) {
        // check to see if the company's industry includes "technology"
        if (grantIndustry[0] === industry[0] || grantIndustry[0] === 'all') {
          return true;
        } else {
          return (
            // return whether company and grant share the same subindustry
            industries[specificIndustry]?.some((item) =>
              grantIndustry?.includes(item)
            ) &&
            industries[specificIndustry]?.some((item) =>
              industry?.includes(item)
            )
          );
        }
      }

      var industryCheck = Object.keys(industries).some(checkIndustry);

      // CHECK 2 = is the company founded more than 3 years ago
      var foundedCheck =
        parseFloat(data[i]?.fields.Eligible_Founded) >= founded ? true : false;

      // CHECK 3 = is the company in the right province and country
      const grantLocation = data[i]?.fields.Eligible_Region?.trim()
        .toLowerCase()
        .split(','); // "canada,united states" => [canada, united states]

      // check if grant location matches company location based on country
      // will return false if grant's locations are not country names or if grant country doesn't match company country
      let locationCheck = grantLocation?.some(
        (location) =>
          countryNames?.includes(location) &&
          countryOfCompany?.includes(location)
      ); // common element between grant location and company location?

      // now check if the grant's state/province names matches the company's state/province names
      if (!locationCheck) {
        locationCheck = grantLocation?.some(
          (location) => cityState[1] === location
        );
      }

      locationCheck =
        locationCheck ||
        data[i]?.fields?.Eligible_Region?.split(',')
          .map((item) => item.trim().toLowerCase())
          .some((region) => provincesOfCompany.includes(region));

      // locationCheck ? console.log('👍 VALID: grant location:', grantLocation, 'company location:', cityState[1], ...countryOfCompany) : console.log('🚫 location invalid');

      // CHECK 4 = does the company have enough employees
      var employeesCheck =
        parseFloat(data[i]?.fields.Eligible_Employees) <= parseFloat(employees)
          ? true
          : false;

      // CHECK 5 = does the company have enough revenue
      var revenueCheck =
        parseFloat(data[i]?.fields.Eligible_Revenue) <=
          parseFloat(employees) * 100000
          ? true
          : false;

      // CHECK 6 = is the grant a favorite, approved by Upgraded
      // var favoriteCheck = data[i].fields.Favourite == "checked" ? true : false
      var favoriteCheck = true;

      // CHECK 7 = are you looking for grants, loan or both
      // if (typeOfMoney == data[i][2] || typeOfMoney == "both") {
      //   var typeCheck = true;
      // } else {
      //   var typeCheck = false;
      // }
      var typeCheck = true;

      // CHECK 8 = does the grant require that the company is profitable?
      var profitabilityCheck =
        data[i]?.fields?.Eligible_Profitable === 'No'
          ? true
          : profitable === 'Yes'
            ? true
            : false;

      // Check 9 : Open or Closed Check. Is the grant or loan currently open?
      var openOrClosedCheck = data[i]?.fields?.Deadline !== "Closed";

      var timeCheck = data[i]?.fields?.TimeEstimateToComplete <= 90;

      const conditionsArray = [
        industryCheck,
        foundedCheck,
        locationCheck,
        employeesCheck,
        revenueCheck,
        favoriteCheck,
        typeCheck,
        profitabilityCheck,
        openOrClosedCheck,
        timeCheck
      ];


      // if (data[i].fields.Name === "OCI - Critical Industrial Technologies initiative") {
      //   console.log("this is OCI");
      //   console.log('company industry', industry);
      //   console.log('grant industry', grantIndustry);
      //   console.log('condition array', conditionsArray);
      // }

      // console.log('conditionsArray', conditionsArray);

      if (!conditionsArray.includes(false)) {
        const upgrade = {
          id: i,
          recordId: data[i]?.id,
          fields: {
            id: i,
            invoiceNumber: '00012',
            href: 'https://buy.stripe.com/aEU03K0pcb4U2vC9AB',
            Tactic: 'Grant',
            Function: data[i]?.fields?.GrantOrLoan,
            client: 'Upgrade #00012',
            pSuccess: parseFloat(data[i].fields.pSuccess) ?? 0.65,
            TTV: '30 - 180',
            client_time_required: '1 - 5',
            icon: ArrowUpCircleIcon,
            AnnualMargin: parseFloat(data[i].fields.Amount),
            ExpectedValue: parseFloat(data[i].fields.Amount) * (parseFloat(data[i].fields.pSuccess) ?? 0.65),
            AdjustedAmount: parseFloat(data[i].fields.AdjustedAmount),
            TimeEstimateToComplete: parseFloat(
              data[i].fields.TimeEstimateToComplete
            ),
            Description: data[i].fields.Description,
            Name: data[i].fields.Name,
            Eligibility_Criteria: data[i].fields.Eligibility_Criteria,
            Eligible_Region: data[i].fields.Eligible_Region,
            Eligible_Industry: data[i].fields.Eligible_Industry,
            Eligible_Employees: data[i].fields.Eligible_Employees,
            Eligible_Revenue: data[i].fields.Eligible_Revenue,
            Eligible_Founded: data[i].fields.Eligible_Founded,
            Eligible_Profitable: data[i].fields.Eligible_Profitable,
            Description_2: data[i].fields.Description_2,
            Eligible_Activities: data[i].fields.Eligible_Activities,
            Eligible_Applicants: data[i].fields.Eligible_Applicants,
            Funding_Amount: data[i].fields.Funding_Amount,
            Deadline_To_Apply: data[i].fields.Deadline_To_Apply,
            Deadline: data[i].fields.Deadline,
            Favourite: data[i].fields.Favourite,
            GrantUrl: data[i].fields.GrantUrl,
            GrantOrLoan: data[i]?.fields?.GrantOrLoan,
            OpenOrClosed: data[i]?.fields?.OpenOrClosed,
            Grant_Goal: data[i]?.fields?.Grant_Goal,
            Verified: data[i]?.fields?.Verified,
            'Verified By': data[i]?.fields?.VerifiedBy,
            'Date Verified': data[i]?.fields?.DataVerified,
            referralEmails: data[i]?.fields?.referralEmails,
            Category: data[i]?.fields?.Category,
            Empty_Grant_Application: data[i]?.fields?.Empty_Grant_Application,
            Successful_Grant_Application:
              data[i]?.fields?.Successful_Grant_Application,
            Internal_Process: data[i]?.fields?.Internal_Process,
            Contact_Info: data[i]?.fields?.Contact_Info,
            QuestionsComplete: data[i]?.fields?.QuestionsComplete,
            SampleAnswersComplete: data[i]?.fields?.SampleAnswersComplete,
          },
        };
        new_array.push(upgrade);

        // console.log(conditionsArray)
        // console.log("company info" + parametersArray)
        // console.log("grant" + data[i].fields)
        // console.log("industrycheck" + industryCheck + "_foundedCheck" + foundedCheck + "_locationCheck" + locationCheck + "_employeesCheck" + employeesCheck + "_revenueCheck" + revenueCheck + "_typeCheck" + typeCheck)
      }
    }

    if (typeOfMoney == 'sum') {
      new_array = new_array.filter(
        (grant) =>
          !flaggedGrants
            .map((grant) => grant.fields.Name)
            .includes(grant.fields.Name)
      );

      // AnnualMargin replaced with ExpectedValue
      sum = new_array.reduce(
        (partialSum, a) =>
          a.fields.ExpectedValue
            ? partialSum + a.fields.ExpectedValue
            : partialSum + 0,
        0
      );
      return sum;
    } else {
      return new_array;
    }
  }

  LogRocket.getSessionURL(function (sessionURL) {
    window.analytics.track('LogRocket', {
      sessionURL: sessionURL,
    });
  });

  return (
    <>
      <div className="overflow-x-clip">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="fixed h-8 w-auto"
                        src="https://s3.amazonaws.com/media.mixrank.com/hero-img/b5ad1559c480e7c9cabc1fab1ef29b6a"
                        alt="Upgraded Logo"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigationNew.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  onClick={() => {
                                    const currentSearch = location.search;
                                    navigate(`/${currentSearch}`);
                                  }}
                                  className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                >
                                  <item.icon
                                    className="text-gray-400 group-hover:text-indigo-600 h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}

                            {admin && (
                              <li>
                                <button
                                  onClick={() => {
                                    const currentSearch = location.search;
                                    window.scrollTo({ top: 0 });
                                    navigate(`/companies${currentSearch}`);
                                  }}
                                  className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                >
                                  <BuildingOffice2Icon
                                    className="text-gray-400 group-hover:text-indigo-600 h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  Companies
                                </button>
                              </li>
                            )}

                            <li key="allGrants" className="cursor-pointer">
                              <button
                                onClick={() => {
                                  const currentSearch = location.search;
                                  window.scrollTo({ top: 0 });
                                  navigate(`/all-grants${currentSearch}`);
                                }}
                                className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              >
                                <MagnifyingGlassIcon
                                  className="text-gray-400 hover:text-indigo-600 hover:bg-gray-50 h-6 w-6 shrink-0"
                                  aria-hidden="true"
                                />
                                All Grants
                              </button>
                            </li>
                          </ul>
                        </li>

                        {/* <li className="mt-auto">
													<button
														href="#"
														className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
													>
														<Cog6ToothIcon className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600" aria-hidden="true" />
														Settings
													</button>
												</li> */}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="overflow-visible">
          <main className="py-0">
            <div className="px-4">
              {company && (
                <SidePanel
                  restricted={restricted}
                  // signedUp={signedUp}
                  // userEmail={userEmail}
                  // setSignedUp={setSignedUp}
                  // setUserEmail={setUserEmail}
                  // setUserDomain={setUserDomain}
                  // setGetEmail={setGetEmail}
                  admin={admin}
                  flaggedGrants={flaggedGrants}
                  setFlaggedGrants={setFlaggedGrants}
                  // secret_1={getQueryVariable(process.env.REACT_APP_QUERY_SECRET_1) === "true"}
                  eligibleGrants={eligibleGrants}
                  setEligibleGrants={setEligibleGrants}
                  open={open}
                  setOpen={setOpen}
                  setFormSubmitted={setFormSubmitted}
                  setCompanyFormNotif={setCompanyFormNotif}
                  company={company}
                  setCompany={setCompany}
                  industries={industries}
                  setIndustries={setIndustries}
                  industryRecords={industryRecords}
                  setIndustryRecords={setIndustryRecords}
                  // setEmailCloseable={setEmailCloseable}
                  find_grants={find_grants}
                  findDiscounts={findDiscounts}
                />
              )}

              <main>
                <Summary
                  // submitEnrich={submitEnrich}
                  reqSavingsNotif={reqSavingsNotif}
                  grantsSum={grantsSum}
                  isLoading={loading}
                  isGrantsLoading={grantLoading}
                  companies={companies}
                  company={company}
                  find_grants={find_grants}
                  techSavings={totalTechSavings}
                  findDiscounts={findDiscounts}
                  getQueryVariable={getQueryVariable}
                />

                <div className="space-y-16 py-5 xl:space-y-10">
                  {company && (
                    <Company
                      key={company.id}
                      company={company}
                      isLoading={loading}
                      getQueryVariable={getQueryVariable}
                      grantWriters={grantWriters}
                      setSideBarOpen={setOpen}
                      userId={userRecord?.id}
                      userFirstName={userRecord?.fields['First Name']}
                      userLastName={userRecord?.fields['Last Name']}
                      submitFormNotif={submitFormNotif}
                      setSubmitFormNotif={setSubmitFormNotif}
                    />
                  )}

                  {/* temporary button to get interestedin grants */}
                  {/* <button
										onClick={() => addGrants()}
									>
										button
									</button> */}

                  {/* List of all Upgrades (Tech savings, Grants, Automations) */}
                  <UpgradeList
                    admin={admin}
                    paid={paid}
                    userRecord={userRecord}
                    company={company}
                    setCompany={setCompany}
                    flaggedGrants={flaggedGrants}
                    setFlaggedGrants={setFlaggedGrants}
                    interestedInGrants={interestedInGrants}
                    setInterestedInGrants={setInterestedInGrants}
                    eligibleGrants={eligibleGrants}
                    companies={companies}
                    getQueryVariable={getQueryVariable}
                    find_grants={find_grants}
                    grantWriters={grantWriters}
                    isLoading={grantLoading}
                    upgrades={upgrades}
                    grantsSum={grantsSum}
                    matchedDiscounts={matchedDiscounts}
                    industries={industries}
                    setTotalTechSavings={setTotalTechSavings}
                    setSideBarOpen={setOpen}
                    questions={questions}
                    answers={answers}
                    setTechPipeline={setTechPipeline}
                    techPipeline={techPipeline}
                  />

                  {company && (
                    <AutomationContext.Provider
                      value={{ automationPeople, setAutomationPeople }}
                    >
                      {company && (
                        <Automation
                          key={company.id}
                          company={company}
                          getQueryVariable={getQueryVariable}
                          grantWriters={grantWriters}
                          fullBlur={!admin && paid !== "basic"}
                        />
                      )}
                    </AutomationContext.Provider>
                  )}

                  {/* Admin Endless Fries Buttons */}
                  {/* {admin && (
										<div className="flex min-h-full flex-1 flex-col justify-center items-center py-12 sm:px-6 lg:px-8 bg-red-50">
											<NotificationButtons find_grants={find_grants} findDiscounts={findDiscounts} />
										</div>
									)} */}

                  {/* Financing */}
                  <div id="financing"></div>
                  <div className="sticky top-[105px] md:top-16 bg-white mx-auto w-full mt-10 z-10 pt-4 border-b mb-20 p-4">
                    <h1 className="text-2xl font-semibold text-gray-900">
                      Financing
                    </h1>
                  </div>
                  <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50 pt-20">
                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[800px]">
                      <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        {company && (
                          <div key={company.id}>
                            <Financing
                              company={company}
                              isLoading={loading}
                              userEmail={user.primaryEmailAddress.emailAddress}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <br />
                  </div>

                  <div id="myreports"></div>
                  <div className="sticky top-[105px] md:top-16 bg-white mx-auto w-full mt-10 z-10 pt-4 border-b mb-20 p-4">
                    <h1 className="text-2xl font-semibold text-gray-900">
                      My Reports
                    </h1>
                  </div>
                  <div
                    className={`relative flex min-h-full flex-1 flex-col justify-center py-12 bg-gray-50 sm:pl-20p sm:pr-20p`}
                  >
                    <div
                      className={`${!admin && paid !== "basic" && 'blurred pointer-events-none'
                        }`}
                    >
                      <div className="rounded-t-xl border-b-2 border-gray-200 bg-white px-4 py-5">
                        <div className="ml-4 mt-4">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <button
                                type="button"
                                className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                              >
                                <span className="sr-only">
                                  View notifications
                                </span>
                                <UserButton afterSignOutUrl="/sign-in" />
                              </button>
                            </div>
                            <div className="ml-4">
                              <h className="text-gray-800 text-2xl text-center font-semibold mb-6">
                                Your reports
                              </h>
                            </div>
                          </div>
                        </div>
                      </div>
                      {userReports.map((report) => (
                        <div className="bg-white border-b border-gray-200 px-4 py-5">
                          <a
                            href={`https://app.getupgraded.ca/?website=${report}`}
                            className="text-blue-500 hover:text-blue-700"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <ArrowTopRightOnSquareIcon className="mb-2 h-6 w-6 inline" />{' '}
                            Savings report for {report}
                          </a>
                        </div>
                      ))}
                    </div>
                    {!admin && paid !== "basic" && (
                      <button
                        type="button"
                        onClick={() => {
                          const currentSearch = location.search;
                          window.scrollTo({ top: 0 });
                          navigate(`/pricing${currentSearch}`);
                        }}
                        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white shadow-xl bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        <LockClosedIcon className="mr-4 h-5 w-5 inline" />{' '}
                        Unlock These Exclusive Savings For $89/mo
                      </button>
                    )}
                  </div>

                  {/* Onboarding */}
                  <div id="onboarding"></div>
                  <div className="sticky top-[105px] md:top-16 bg-white mx-auto w-full mt-10 z-10 pt-4 border-b mb-20 p-4">
                    <h1 className="text-2xl font-semibold text-gray-900">
                      Onboarding
                    </h1>
                  </div>
                  <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50 pt-20">
                    <div className="sm:mx-auto sm:w-full sm:max-w-xl">
                      <img
                        className="mx-auto h-10 w-auto"
                        src="https://assets.website-files.com/634f60226f66af42a384d5b7/647819351547cbfe06a601b8_upgraded-full-word-logo-no-background.png"
                        alt="Your Company"
                      />
                      <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Get Started with Upgraded
                      </h2>
                      <h5 className="text-center">
                        You're on your way to saving. Follow the steps below to
                        complete setup.
                      </h5>
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[800px]">
                      <div className="bg-white px-6 pt-4 pb-8 shadow sm:rounded-lg sm:px-12">
                        {/* Onboarding */}
                        {company && (
                          <Onboarding
                            key={company.id}
                            company={company}
                            isLoading={loading}
                            setSideBarOpen={setOpen}
                          />
                        )}
                      </div>
                    </div>
                    <br />
                  </div>

                  {admin && (
                    <IndustriesMappingModal
                      open={showIndustryModal}
                      setOpen={setShowIndustryModal}
                      company={false}
                      industries={industries}
                      setIndustries={setIndustries}
                      industryRecords={industryRecords}
                      setIndustryRecords={setIndustryRecords}
                    />
                  )}

                  {/* Savings estimate notificaiton */}
                  <Notification
                    showNotif={reqSavingsNotif.showNotif}
                    setShowNotif={(val) =>
                      setShowNotif(val, setReqSavingsNotif)
                    }
                    contents={reqSavingsNotif.contents}
                  />

                  {/* Company Info Form Submit Notification*/}
                  <Notification
                    showNotif={companyFormNotif.showNotif}
                    setShowNotif={(val) =>
                      setShowNotif(val, setCompanyFormNotif)
                    }
                    contents={companyFormNotif.contents}
                  />
                  {/* Company Info Form Submit Notification*/}
                  <Notification
                    showNotif={submitFormNotif.showNotif}
                    setShowNotif={(val) =>
                      setSubmitFormNotif(val, setSubmitFormNotif)
                    }
                    contents={submitFormNotif.contents}
                  />

                  {/* JIT Modal */}
                  {showJITModal && (
                    <JITModal
                      website={getQueryVariable('website')}
                      setRequestEmail={setRequestEmail}
                      setJITModalSubmit={setJITModalSubmit}
                      countDown={2}
                      userEmail={user.primaryEmailAddress.emailAddress}
                    />
                  )}
                </div>
              </main>
            </div>
          </main>
        </div>
      </div>

      <Notification
        showNotif={emailModalNotif.showNotif}
        setShowNotif={(val) => setShowNotif(val, setEmailModalNotif)}
        contents={emailModalNotif.contents}
      />

      <Notification
        showNotif={submitEnrichNotif.showNotif}
        setShowNotif={(val) => setShowNotif(val, setSubmitEnrichNotif)}
        contents={submitEnrichNotif.contents}
      />
      <Notification
        showNotif={techPipeline.showNotif}
        setShowNotif={(val) => setTechPipeline(val, setTechPipeline)}
        contents={techPipeline.contents}
      />
    </>
  );
}
